import React from "react"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { motion } from 'framer-motion'
import { fadeBumpDelay, fadeBump, fade } from "../helpers/transitionHelper"
import { injectIntl } from "gatsby-plugin-intl-v3"
import { graphql } from "gatsby"
import SEO from "../components/seo"

const GalleryPage = ({ intl, data }) => {
  const { title, images } = data.contentfulGallery;
  return <>
    <SEO
      title={intl.formatMessage({ id: "gallery" })}
      image={getSrc(images[0])}
    />
    <motion.section
      initial="initial"
      animate="enter"
      exit="exit"
    >
      <div className="pt-10 md:pt-0 pb-10 md:pb-0 overflow-hidden" data-scroll-section>
        <div className="w-full ml-auto flex flex-wrap md:h-screen mb-6 md:mb-10vw">
          <motion.div
            variants={fade}
            className="block md:hidden pb-2"
          >
            <h1 className="font-display uppercase tracking-tighter text-11xlvw mb-3 pb-0 px-4">{title}</h1>
          </motion.div>

          <div className="w-full md:w-7/12 h-full flex flex-wrap relative order-2 md:order-1">
            <div className="w-full self-center">
              <motion.div
                variants={fadeBump}
                className="w-full md:w-7/12 xl:w-5/12 mx-auto px-4 md:px-0 max-w-2xl"
              >
                <span data-scroll data-scroll-speed="0.5" className="block content">
                  {intl.formatMessage({ id: "photosText" })}
                </span>
              </motion.div>
            </div>
            <motion.div
              variants={fadeBumpDelay}
              className="hidden md:block md:absolute z-10 bottom-0 left-0 p-6 md:p-12 self-end w-full"
            >
              <h1 className="font-display tracking-tighter uppercase md:text-7xlvw mb-0 pb-0" data-scroll data-scroll-speed="0.5">{title}</h1>
            </motion.div>
          </div>

          <motion.div variants={fade} className="w-full md:w-5/12 h-72 md:h-full relative order-1 md:order-2 mb-6 md:mb-0 md:pl-5">
            <span className="text-lg md:text-2xl uppercase font-sans tracking-widest upright absolute bottom-0 md:bottom-auto md:top-0 right-0 md:right-auto md:left-0 md:mt-5vw md:-ml-0 z-10 mr-5 md:-mr-0 mb-12 md:mb-0" data-scroll data-scroll-speed="1.5">
              {intl.formatMessage({ id: "photosTextShort" })}</span>


            <div className="w-full h-full relative overflow-hidden" data-scroll data-scroll-speed="0">
              <div className="w-full relative overflow-hidden" data-scroll>
                <div data-scroll data-scroll-speed="0.5" className="overflow-hidden -m-10">
                  <GatsbyImage
                    image={data.contentfulMainPage.vertical[1].gatsbyImageData}
                    className="w-full h-full object-cover object-center image-scale-in p-10 opacity-70 hover:opacity-100 focus:opacity-100 transition duration-500 ease-in-out"
                    alt={data.contentfulMainPage.vertical[1].title} />
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <div className="pb-10 md:pb-10 px-0 md:px-12">
        <motion.div variants={fade} className="flex flex-wrap w-full flex-row justify-center">
          {
            images.map((image, i) => (
              <div key={i} className="mb-6 md:mb-12 px-3 w-full md:w-1/2 overflow-hidden" data-scroll-section>
                <div className="relative overflow-hidden" data-scroll data-scroll-speed="0">
                  <div className="relative overflow-hidden" data-scroll>
                    <div data-scroll data-scroll-speed="0.5" className="overflow-hidden">
                      <figure className="w-full h-full overflow-hidden">
                        <GatsbyImage
                          image={image.gatsbyImageData}
                          objectFit="contain"
                          className="w-full h-full"
                          alt={image.title} />
                        {image.description &&
                        <figcaption className="normal-case italic text-center px-0 md:px-3">
                          {image.description}
                        </figcaption>
                        }
                      </figure>
                    </div>

                  </div>
                </div>
              </div>
            ))
          }
        </motion.div>
      </div>
    </motion.section>
  </>;
}

export default injectIntl(GalleryPage)

export const query = graphql`
    query($language: String) {
        contentfulGallery(
            contentful_id: {eq: "Kk1t13ZOszvnPl2L48u2Z"}
            node_locale: {eq: $language}
        ) {
            title
            images {
                title
                gatsbyImageData (layout: CONSTRAINED, width: 800, placeholder: BLURRED)
                description
            }
        }
        contentfulMainPage(
            contentful_id: { eq: "3IemKmfKhhIl4iKQaLPfme" }
            node_locale: { eq: $language }
        ) {
            vertical {
                title
                gatsbyImageData(layout: CONSTRAINED, width: 1200, height: 1600)
            }
        }
    }
`;
